import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import PlainHeader from "components/ui/base/PlainHeader";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import TestimonialWithImageIrene from "components/ui/extended/TestimonialWithImageIrene";
import Customers from "components/ui/extended/Customers";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import FeatureGrid from "components/ui/base/FeatureGrid";

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: IndexPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.smart-assignment-rules.seo.title")}
        description={t("pages.features.smart-assignment-rules.seo.description")}
        image={{
          relativePath: "meta/live-chat-smart-assignment-rules.jpg",
          alt: "Live Chat Smart Assignment Rules",
        }}
      />

      <PlainHeader
        title={t("pages.features.smart-assignment-rules.title")}
        description={t("pages.features.smart-assignment-rules.description")}
        ctaRegister={{ show: true }}
        titleMaxWidth={700}
        descriptionMaxWidth={500}
        image={{
          relativePath: "pages/features/smart-assignment-rules/header.png",
          alt: "Customerly",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Smart Assignment Rules",
            path: "/features/smart-assignment-rules/",
          },
        ]}
      />

      <ImageWithDescription
        title={t("pages.features.smart-assignment-rules.1.title")}
        description={t("pages.features.smart-assignment-rules.1.subtitle")}
        image={{
          relativePath: "pages/features/smart-assignment-rules/url.png",
          alt: t("pages.features.smart-assignment-rules.1.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.features.smart-assignment-rules.2.title")}
        description={t("pages.features.smart-assignment-rules.2.subtitle")}
        id={"screen-share"}
        image={{
          relativePath:
            "pages/features/smart-assignment-rules/custom-properties.png",
          alt: t("pages.features.smart-assignment-rules.2.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.features.smart-assignment-rules.3.title")}
        description={t("pages.features.smart-assignment-rules.3.subtitle")}
        image={{
          relativePath: "pages/features/smart-assignment-rules/priority.png",
          alt: t("pages.features.smart-assignment-rules.3.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <FeatureGrid
        title={t("pages.features.smart-assignment-rules.others.title")}
        centered={false}
        sections={[
          {
            title: t(
              "pages.features.smart-assignment-rules.others.helpCenter.title"
            ),
            description: t(
              "pages.features.smart-assignment-rules.others.helpCenter.description"
            ),
            image: {
              alt: "Help Center",
              relativePath: "icons/help-center-software.png",
            },
            links: [
              {
                text: t(
                  "pages.features.smart-assignment-rules.others.helpCenter.cta"
                ),
                path: "/features/help-center/",
              },
            ],
          },
          {
            title: t(
              "pages.features.smart-assignment-rules.others.surveys.title"
            ),
            description: t(
              "pages.features.smart-assignment-rules.others.surveys.description"
            ),
            image: {
              alt: "Surveys",
              relativePath: "icons/surveys.png",
            },
            links: [
              {
                text: t(
                  "pages.features.smart-assignment-rules.others.surveys.cta"
                ),
                path: "/features/surveys/",
              },
            ],
          },
          {
            title: t(
              "pages.features.smart-assignment-rules.others.funnels.title"
            ),
            description: t(
              "pages.features.smart-assignment-rules.others.funnels.description"
            ),
            image: {
              alt: "Funnels",
              relativePath: "icons/funnel-marketing.png",
            },
            links: [
              {
                text: t(
                  "pages.features.smart-assignment-rules.others.funnels.cta"
                ),
                path: "/features/marketing-funnel/",
              },
            ],
          },
        ]}
      />

      <TestimonialWithImageIrene />

      <RegisterForCustomerService />

      <Customers />
    </GlobalWrapper>
  );
};

export const indexPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
